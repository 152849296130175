<template>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.9 98.9">
      <g>
        <path class="cls-2"
              d="m51.55,80.57c-4.36-.03-7.98-1.32-11.37-3.36-2.29-1.38-4.35-3.06-6.15-5.02-2.98-3.24-5.33-6.89-6.81-11.05-.61-1.73-1.14-3.51-1.53-5.31-.15-.68-.45-1.1-.89-1.53-1.65-1.63-2.35-3.68-2.67-5.91-.23-1.57-.22-3.12.16-4.67.31-1.24.94-2.26,2.18-2.75.44-.18.58-.43.58-.91,0-1.46-.03-2.94.15-4.38.24-1.99.78-3.92,1.55-5.79,1.07-2.6,2.62-4.88,4.58-6.85,2.05-2.05,4.43-3.66,7.11-4.83,2.28-1,4.67-1.62,7.09-2.13.76-.16,1.54-.3,2.31-.31,2.22-.04,4.46-.17,6.67.01,3.87.32,7.6,1.27,11.08,3.08,2.41,1.26,4.6,2.84,6.43,4.84,2.59,2.82,4.32,6.14,5.15,9.89.3,1.35.35,2.76.46,4.15.06.86,0,1.74.03,2.6,0,.18.19.43.36.51,2.03.87,2.6,2.59,2.66,4.59.06,1.9.05,3.82-.74,5.59-.47,1.04-.99,2.15-1.79,2.9-.96.9-1.12,1.97-1.37,3.08-1.03,4.6-3.01,8.75-5.85,12.5-2.95,3.91-6.56,7.01-11.01,9.08-2.04.95-4.17,1.65-6.44,1.81-.76.05-1.52.14-1.92.17Zm-22.53-37.16c-.95.06-1.81.1-2.67.18-.51.05-.89.23-1.04.84-.45,1.8-.29,3.55.29,5.28.41,1.22.97,2.33,2.2,2.99.33.18.63.69.69,1.09.33,2.43.99,4.75,1.89,7.02,1.25,3.17,3.02,6.02,5.31,8.52,1.32,1.44,2.79,2.78,4.36,3.93,3.78,2.77,7.9,4.64,12.78,4.1,1.9-.21,3.73-.7,5.45-1.49,3.75-1.73,6.89-4.25,9.53-7.44,2.08-2.53,3.71-5.31,4.86-8.37.74-1.97,1.3-4,1.52-6.11.06-.6.36-1.12.96-1.46.46-.26.9-.66,1.18-1.1,1.37-2.11,1.52-4.45,1.15-6.87-.09-.59-.51-.86-1.02-.91-.62-.07-1.25-.03-1.88-.02-.6,0-1.26-.15-1.78.05-2.9,1.11-5.86,1.18-8.82.49-2.98-.69-5.81-1.87-8.54-3.26-2.81-1.43-5.58-2.92-8.4-4.33-2.38-1.18-4.8-2.24-7.57-2.11-1.2.06-2.15.52-3.04,1.28-.62.54-.78,1.25-1.05,1.94-.78,2-2.32,3.41-3.84,4.82-.71.65-1.52,1.27-2.53.94Zm45.73-3.3c-.19-1.87-.18-3.97-.64-5.97-.97-4.2-3.15-7.69-6.62-10.39-4.05-3.15-8.72-4.57-13.71-4.92-3.31-.23-6.64-.08-9.92.79-3.41.9-6.54,2.27-9.21,4.58-2.92,2.53-4.96,5.65-5.9,9.46-.55,2.21-.77,4.43-.63,6.77.41-.05.87.03,1.13-.16,1.5-1.09,2.89-2.37,3.4-4.17.43-1.55,1.43-2.52,2.6-3.38,1.72-1.27,3.66-1.61,5.8-1.35,2.05.25,3.98.83,5.82,1.69,2.38,1.1,4.71,2.32,7.03,3.53,3.21,1.68,6.42,3.37,9.95,4.31,2.13.57,4.28.75,6.45.23,1.38-.33,2.78-.63,4.45-1.01Z" />
        <path class="cls-2"
              d="m51.23,71.31c-2.11-.18-3.95-.7-5.31-2.29-.5-.59-.93-1.19-.62-2.1.38-1.14,2.09-1.1,2.71-.31,1.67,2.15,5.12,2.08,6.79-.09.4-.52,1.41-.68,2.02-.32.65.39.94,1.29.54,2-.85,1.5-2.21,2.36-3.86,2.76-.77.19-1.58.25-2.28.35Z" />
        <path class="cls-2"
              d="m58.88,49.07c2.06.12,3.65.97,4.63,2.88.39.75.3,1.77-.68,2.3-.73.39-1.71-.05-2.14-.9-.61-1.22-1.63-1.53-2.66-.75-.27.2-.43.55-.6.85-.4.71-1.42,1.17-2.05.82-.6-.34-1.16-1.13-.79-2.02.65-1.55,2.48-3.28,4.3-3.17Z" />
        <path class="cls-2"
              d="m48.29,52.75c-.07.8-.45,1.36-1.08,1.54-.71.2-1.41-.08-1.82-.74-.07-.12-.14-.25-.2-.37-.33-.65-.92-1.05-1.58-1.02-.71.03-1.3.5-1.62,1.19-.33.72-1.02,1.07-1.82.94-.52-.09-1.26-.78-1.19-1.34.07-.58.3-1.21.64-1.68,2.37-3.24,6.5-2.82,8.4.61.14.26.18.57.26.86Z" />
      </g>
      <g>
        <polygon class="cls-1" points="98.9 98.9 66.76 98.9 66.76 95.9 95.9 95.9 95.9 66.76 98.9 66.76 98.9 98.9" />
        <polygon class="cls-1" points="32.14 98.9 0 98.9 0 66.76 3 66.76 3 95.9 32.14 95.9 32.14 98.9" />
        <polygon class="cls-1" points="3 32.14 0 32.14 0 0 32.14 0 32.14 3 3 3 3 32.14" />
        <polygon class="cls-1" points="98.9 32.14 95.9 32.14 95.9 3 66.76 3 66.76 0 98.9 0 98.9 32.14" />
      </g>
    </svg>
  </template>
  
  <script>
  export default {
    name: "FaceClrearlyVisibleSvg"
  };
  </script>
  
  <style scoped>
  .cls-1 {
    fill: #a54ef0;
  }
  
  .cls-2 {
    fill: #173dd0;
  }
  </style>