<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
         y="0px"
         viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
  
      <g>
      <g>
          <path class="st0" d="M43.56,66.8c5.23-0.91,9.12,0.93,11.72,5.53c1.9-2.41,4.81-3,7.48-0.67c1.74-5,5.24-7.51,10.58-7.53
              c-0.13-0.11-0.24-0.22-0.36-0.31c-1.59-1.32-3.19-2.62-4.77-3.95c-0.64-0.54-1.32-0.6-2.07-0.34c-0.57,0.2-1.01,0.57-1.45,0.96
              c-0.39,0.35-0.84,0.35-1.15,0.02c-0.31-0.33-0.27-0.83,0.1-1.19c0.36-0.34,0.74-0.65,1.17-0.9c1.37-0.82,3.15-0.88,4.54,0.32
              c2.42,2.09,4.92,4.1,7.39,6.14c0.18,0.15,0.37,0.28,0.57,0.39c3.76,2.05,5.69,5.21,5.66,9.5c-0.04,4.77-3.75,9.03-8.47,9.81
              c-6.54,1.08-12.27-3.95-12.04-10.56c0.01-0.17,0-0.33-0.13-0.47c-0.56-0.65-1.18-1.22-1.98-1.56c-0.9-0.38-1.77-0.32-2.6,0.2
              c-0.76,0.48-1.29,1.17-1.74,1.94c-0.08,0.15-0.07,0.29-0.03,0.44c0.23,1.01,0.3,2.04,0.22,3.07c-0.39,4.68-4,8.57-8.6,9.26
              c-4.76,0.71-9.3-1.87-11.08-6.29c-0.25-0.63-0.38-1.29-0.61-1.92c-0.06-0.64-0.12-1.29-0.17-1.93c0.02-0.09,0.05-0.17,0.06-0.26
              c0.14-3.2,1.47-5.8,3.98-7.8c0.19-0.15,0.37-0.33,0.52-0.52C41.03,67.2,43.56,66.8,43.56,66.8z M73.5,83.01
              c4.75-0.44,8.24-4.68,7.79-9.46c-0.45-4.74-4.67-8.23-9.41-7.78c-4.78,0.45-8.27,4.66-7.81,9.43
              C64.51,79.96,68.73,83.45,73.5,83.01z M45.23,68.16c-4.76,0.44-8.27,4.63-7.85,9.37c0.42,4.78,4.66,8.31,9.44,7.88
              c4.74-0.43,8.24-4.65,7.81-9.4C54.21,71.24,49.99,67.72,45.23,68.16z" />
      <path class="st0" d="M79.66,73.11c-0.47-2.49-2-4.44-4.7-5.43c-0.5-0.19-0.96,0.01-1.1,0.44c-0.15,0.44,0.07,0.85,0.58,1.04
              c1.7,0.62,2.86,1.77,3.45,3.47c0.11,0.32,0.19,0.65,0.24,0.98c0.1,0.69,0.42,1.04,0.92,0.98C79.55,74.54,79.77,74.13,79.66,73.11z
              " />
      <path class="st0" d="M69.61,68.04c-0.09,0.03-0.22,0.07-0.34,0.14c-0.75,0.41-1.41,0.93-1.95,1.58c-0.34,0.4-0.33,0.84-0.01,1.15
              c0.34,0.32,0.82,0.3,1.18-0.11c0.48-0.53,1.03-0.95,1.64-1.32c0.35-0.21,0.44-0.55,0.31-0.92C70.32,68.19,70.04,68.04,69.61,68.04
              z" />
      <path class="st0" d="M53.02,75.75c-0.51-2.75-2.03-4.66-4.66-5.65c-0.55-0.21-0.98-0.04-1.15,0.41c-0.17,0.46,0.05,0.85,0.6,1.06
              c2.09,0.8,3.33,2.3,3.67,4.52c0.1,0.64,0.45,0.98,0.94,0.9c0.48-0.08,0.7-0.47,0.62-1.1C53.02,75.84,53.02,75.8,53.02,75.75z" />
      <path class="st0" d="M42.97,70.44c-0.14,0-0.28,0.08-0.41,0.15c-0.72,0.4-1.35,0.91-1.88,1.53c-0.37,0.43-0.37,0.87-0.03,1.19
              c0.34,0.31,0.82,0.28,1.18-0.12c0.47-0.52,1.01-0.94,1.61-1.29c0.34-0.2,0.48-0.52,0.36-0.91C43.69,70.63,43.35,70.41,42.97,70.44
              z" />
      </g>
        <g>
          <path class="st0" d="M40.27,69.2l-1.57-1.57c-0.12-0.12-0.23-0.23-0.34-0.33c-1.53-1.38-3.08-2.75-4.6-4.15
              c-0.62-0.57-1.29-0.65-2.05-0.43c-0.57,0.17-1.03,0.53-1.49,0.9c-0.4,0.33-0.86,0.31-1.15-0.03c-0.3-0.34-0.24-0.84,0.15-1.18
              c0.37-0.33,0.77-0.61,1.2-0.85c1.4-0.77,3.18-0.74,4.52,0.51c2.33,2.19,4.75,4.3,7.13,6.44c0.17,0.15-0.17-0.14,0.02-0.03
               M46.24,77.38" />
      </g>
  </g>
      <g>
      <g>
          <path class="st1" d="M96.13,68c-9.85,25.38-38.52,38.02-63.9,28.16S-5.78,57.64,4.07,32.26S42.59-5.75,67.97,4.1
              S105.98,42.61,96.13,68z M5.8,32.93c-9.48,24.43,2.68,52.02,27.11,61.5c24.43,9.48,52.02-2.68,61.5-27.11S91.73,15.3,67.3,5.82
              C42.87-3.66,15.28,8.5,5.8,32.93z" />
      </g>
        <g>
  
              <rect x="45.63" y="2.8" transform="matrix(0.9151 -0.4033 0.4033 0.9151 -16.8478 23.1564)" class="st1" width="1.85"
              height="97.56" />
      </g>
  </g>
      <g>
      <path class="st0" d="M6.68,45.89c0.44-1.11,1.45-1.71,2.34-2.35c3.22-2.32,6.49-4.59,9.78-6.81c0.67-0.45,0.56-1.07,0.63-1.6
          c0.39-2.87,1.09-5.66,2.03-8.4c1-2.91,2.32-5.67,4.2-8.12c1.42-1.86,3.22-3.32,5.22-4.55c2.59-1.59,5.43-2.57,8.34-3.35
          c1.27-0.34,2.57-0.57,3.87-0.74c0.41-0.05,0.49-0.29,0.53-0.51c0.37-2.12,2.66-3.21,4.76-2.81c1.33,0.25,2.44,0.74,3.11,1.95
          C51.72,9,51.85,9.5,51.88,9.97c0.03,0.48,0.15,0.7,0.64,0.87c2.7,0.92,5.27,2.11,7.62,3.74c2.82,1.95,5.11,4.38,6.81,7.38
          c1.21,2.14,2,4.42,2.47,6.81c0.26,1.36,0.28,2.77,0.4,4.16c0.06,0.74,0.17,1.49,0.11,2.23c-0.16,1.97-0.11,3.97-0.74,5.88
          c-0.51,1.55-1.61,2.42-3.2,2.75c-1.13,0.23-2.23,0.04-3.35-0.12c-1.1-0.16-2.21-0.18-3.32-0.27c-0.51-0.04-1.02-0.09-1.53-0.1
          c-0.12,0-0.28,0.12-0.37,0.23c-2.2,2.78-4.83,5.08-7.87,6.89c-2.24,1.34-4.61,2.38-7.19,2.88c-1.14,0.22-2.29,0.37-3.44,0.5
          c-0.76,0.09-1.52,0.09-2.29,0.13c-3.13,0.14-5.92-0.78-8.45-2.6c-1.74-1.25-3.57-2.4-5.68-2.92c-1.69-0.42-3.4-0.75-5.13-0.97
          c-1.35-0.17-2.73-0.15-4.1-0.14c-1.16,0.01-2.3,0.25-3.36,0.75c-1.78,0.84-2.6,0.36-3.24-1.01C6.68,46.64,6.68,46.27,6.68,45.89z
           M51.09,40.68c0.08-0.29,0.14-0.48,0.19-0.67c1.11-3.85,1.87-7.78,2.09-11.77c0.18-3.12,0.17-6.26-0.72-9.32
          c-0.82-2.81-2.2-5.23-4.72-6.86c-0.22-0.14-0.56-0.29-0.79-0.22c-1.08,0.32-2.17,0.63-3.19,1.09c-3.81,1.7-6.35,4.71-8.36,8.24
          c-1.28,2.25-2.29,4.63-2.95,7.13c-0.66,2.51-1.13,5.08-1.65,7.63c-0.02,0.12,0.2,0.39,0.35,0.43c1,0.26,2.01,0.46,3.01,0.71
          c2.29,0.56,4.57,1.16,6.87,1.68c2.3,0.53,4.62,1,6.94,1.46C49.12,40.4,50.09,40.52,51.09,40.68z M9.65,45.65
          c1.42-0.19,2.65-0.44,3.89-0.49c1.98-0.08,3.95,0,5.92,0.33c2.19,0.37,4.33,0.83,6.29,1.89c1.51,0.81,2.92,1.81,4.37,2.72
          c1.68,1.05,3.49,1.68,5.48,1.68c1.16,0,2.33,0,3.47-0.14c1.32-0.16,2.64-0.41,3.93-0.75c2.88-0.76,5.51-2.07,7.89-3.87
          c1.46-1.1,2.88-2.24,3.98-3.72c-0.26-0.06-0.49-0.14-0.73-0.17c-1.94-0.26-3.9-0.42-5.83-0.77c-2.6-0.47-5.19-1.05-7.77-1.66
          c-4.83-1.14-9.6-2.6-14.6-2.82c-1.67-0.07-3.34-0.02-5.01,0c-0.12,0-0.25,0.11-0.36,0.19c-2.15,1.48-4.3,2.96-6.45,4.45
          C12.66,43.53,11.21,44.56,9.65,45.65z M52.24,12.99c-0.01,0.05-0.02,0.1-0.04,0.15c0.12,0.17,0.23,0.33,0.35,0.5
          c2.06,2.98,2.75,6.39,2.96,9.9c0.11,1.88-0.01,3.78-0.11,5.67c-0.09,1.55-0.23,3.1-0.51,4.63c-0.42,2.26-0.98,4.5-1.52,6.74
          c-0.08,0.34-0.01,0.41,0.26,0.44c0.39,0.05,0.77,0.11,1.16,0.12c1.3,0.05,2.61,0.08,3.92,0.13c0.68,0.03,1.36,0.07,2.04,0.13
          c1.49,0.12,2.98,0.29,4.48,0.37c1.21,0.06,1.87-0.61,2.06-1.61c0.29-1.56,0.42-3.16,0.52-4.75c0.06-0.92-0.02-1.86-0.12-2.79
          c-0.17-1.58-0.29-3.17-0.64-4.72c-0.94-4.16-3.13-7.56-6.4-10.3c-2-1.67-4.22-2.92-6.6-3.93C53.45,13.4,52.84,13.21,52.24,12.99z
           M39.97,12.81c-0.41,0.06-0.69,0.09-0.96,0.14c-0.08,0.01-0.14,0.09-0.22,0.11c-2.22,0.66-4.39,1.44-6.41,2.62
          c-1.89,1.11-3.62,2.42-4.96,4.16c-1.63,2.12-2.89,4.47-3.7,7.01c-0.71,2.23-1.29,4.49-1.88,6.76c-0.18,0.67-0.18,1.39-0.26,2.06
          c2.5,0.09,4.93,0.18,7.28,0.26c0.34-1.85,0.57-3.69,1.01-5.48c0.97-3.89,2.28-7.66,4.37-11.12c1.15-1.91,2.51-3.66,4.12-5.2
          C38.84,13.69,39.37,13.3,39.97,12.81z M45.86,9.62c1.29,0.08,2.6,0.15,3.98,0.23c-0.63-1.03-1.55-1.21-2.58-1.17
          C46.63,8.71,46.03,9.14,45.86,9.62z" />
  </g>
  </svg>
  </template>
  
  <script>
  export default {
    name: "AvoidWearablesSvg"
  };
  </script>
  
  <style scoped>
  .st0 {
    fill: #173DD0;
  }
  
  .st1 {
    fill: #A54EF0;
  }
  </style>