<template>
  <div v-if="isLoading" class="loading appBackGround d-flex iframe-customization">
    <v-progress-circular
      :width="3"
      color="#173dd0"
      indeterminate
    ></v-progress-circular>
    <span class="pl-2"> Loading...</span>
  </div>
  <div v-else class="appBackGround d-flex iframe-customization">
    <div class="iframe-main">
      <!-- iframe -->
      <div class="iframe">
        <div class="iframe-header">
          <span
            v-if="
              selectedScreen !== 'Decline Screen' &&
              selectedScreen !== 'Camera Screen'
            "
            :style="getCustomization.theme_config.consentScreen.heading"
            class="heading"
            >Consent</span
          >
        </div>
        <div :class="['iframe-content', { 'no-padding': selectedScreen }]">
          <!-- Conditionally render components based on selectedScreen -->
          <IframeDeclineScreen
            v-if="
              selectedScreen === 'Decline Screen' ||
              selectedScreen === 'Buttons'
            "
          />
          <IframeCameraScreen v-else-if="selectedScreen === 'Camera Screen'" />
          <IframeLoader v-else-if="selectedScreen === 'Loader'" />
          <div v-else>
            <div class="d-flex align-baseline mt-10 ml-4">
              <div class="col-1">
                <div class="circle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60%"
                    height="100%"
                    viewBox="0 0 7 7"
                    fill="none"
                  >
                    <path
                      d="M2.56973 1.4688C2.51391 1.46769 2.45915 1.48525 2.41302 1.51905C2.3669 1.55285 2.33169 1.60123 2.31227 1.65748C2.29285 1.71373 2.29018 1.77507 2.30463 1.83303C2.31908 1.89098 2.34993 1.94267 2.39292 1.98095L4.03805 3.49449L2.3919 5.00802C2.36183 5.03158 2.33663 5.06158 2.31789 5.09614C2.29914 5.13069 2.28727 5.16905 2.28302 5.20878C2.27876 5.24851 2.28222 5.28876 2.29316 5.32699C2.30411 5.36521 2.32231 5.40058 2.34662 5.43087C2.37092 5.46116 2.40081 5.4857 2.43439 5.50295C2.46796 5.5202 2.5045 5.52978 2.5417 5.53109C2.57889 5.53241 2.61594 5.52542 2.6505 5.51057C2.68505 5.49572 2.71637 5.47334 2.74247 5.44483L4.62436 3.71617C4.65396 3.689 4.67772 3.65526 4.69402 3.61727C4.71031 3.57929 4.71875 3.53795 4.71875 3.49613C4.71875 3.4543 4.71031 3.41297 4.69402 3.37498C4.67772 3.33699 4.65396 3.30325 4.62436 3.27609L2.74247 1.54196C2.69478 1.49634 2.63356 1.47041 2.56973 1.4688Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>

              <div
                :style="getCustomization.theme_config.consentScreen.description"
              >
                I declare that I am at least 16 years of age
              </div>
            </div>

            <div class="d-flex align-baseline ml-4">
              <div class="col-1">
                <div class="circle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60%"
                    height="100%"
                    viewBox="0 0 7 7"
                    fill="none"
                  >
                    <path
                      d="M2.56973 1.4688C2.51391 1.46769 2.45915 1.48525 2.41302 1.51905C2.3669 1.55285 2.33169 1.60123 2.31227 1.65748C2.29285 1.71373 2.29018 1.77507 2.30463 1.83303C2.31908 1.89098 2.34993 1.94267 2.39292 1.98095L4.03805 3.49449L2.3919 5.00802C2.36183 5.03158 2.33663 5.06158 2.31789 5.09614C2.29914 5.13069 2.28727 5.16905 2.28302 5.20878C2.27876 5.24851 2.28222 5.28876 2.29316 5.32699C2.30411 5.36521 2.32231 5.40058 2.34662 5.43087C2.37092 5.46116 2.40081 5.4857 2.43439 5.50295C2.46796 5.5202 2.5045 5.52978 2.5417 5.53109C2.57889 5.53241 2.61594 5.52542 2.6505 5.51057C2.68505 5.49572 2.71637 5.47334 2.74247 5.44483L4.62436 3.71617C4.65396 3.689 4.67772 3.65526 4.69402 3.61727C4.71031 3.57929 4.71875 3.53795 4.71875 3.49613C4.71875 3.4543 4.71031 3.41297 4.69402 3.37498C4.67772 3.33699 4.65396 3.30325 4.62436 3.27609L2.74247 1.54196C2.69478 1.49634 2.63356 1.47041 2.56973 1.4688Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>

              <div
                :style="getCustomization.theme_config.consentScreen.description"
              >
                I shall be fully responsible in case I provide wrong information
                or any proof, I use is the face, forged, counterfeit, etc.
              </div>
            </div>

            <div class="d-flex align-baseline ml-4">
              <div class="col-1">
                <div class="circle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60%"
                    height="100%"
                    viewBox="0 0 7 7"
                    fill="none"
                  >
                    <path
                      d="M2.56973 1.4688C2.51391 1.46769 2.45915 1.48525 2.41302 1.51905C2.3669 1.55285 2.33169 1.60123 2.31227 1.65748C2.29285 1.71373 2.29018 1.77507 2.30463 1.83303C2.31908 1.89098 2.34993 1.94267 2.39292 1.98095L4.03805 3.49449L2.3919 5.00802C2.36183 5.03158 2.33663 5.06158 2.31789 5.09614C2.29914 5.13069 2.28727 5.16905 2.28302 5.20878C2.27876 5.24851 2.28222 5.28876 2.29316 5.32699C2.30411 5.36521 2.32231 5.40058 2.34662 5.43087C2.37092 5.46116 2.40081 5.4857 2.43439 5.50295C2.46796 5.5202 2.5045 5.52978 2.5417 5.53109C2.57889 5.53241 2.61594 5.52542 2.6505 5.51057C2.68505 5.49572 2.71637 5.47334 2.74247 5.44483L4.62436 3.71617C4.65396 3.689 4.67772 3.65526 4.69402 3.61727C4.71031 3.57929 4.71875 3.53795 4.71875 3.49613C4.71875 3.4543 4.71031 3.41297 4.69402 3.37498C4.67772 3.33699 4.65396 3.30325 4.62436 3.27609L2.74247 1.54196C2.69478 1.49634 2.63356 1.47041 2.56973 1.4688Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
              <div
                :style="getCustomization.theme_config.consentScreen.description"
              >
                I agree to the collection, processing, and storage of my
                personal information, including biometric data, by facia.ai for
                the purpose(s) of verifying that the information I provide is
                true and accurate to the best of my knowledge
              </div>
            </div>
            <div class="concent-checkbox ml-4">
              <v-checkbox color="#173dd0">
                <template v-slot:label>
                  <div
                    class="ml-2"
                    :style="
                      getCustomization.theme_config.consentScreen.description
                    "
                  >
                    I agree with
                    <a
                      target="_blank"
                      style="text-decoration: none; cursor: pointer"
                      href="#"
                    >
                      Privacy Policy
                    </a>
                    and
                    <a
                      target="_blank"
                      style="text-decoration: none; cursor: pointer"
                      href="#"
                    >
                      Terms of Use
                    </a>
                  </div>
                </template>
              </v-checkbox>
            </div>
          </div>
        </div>
        <div class="iframe-footer">
          <span class="brand-logo" :style="getCustomization.theme_config.logo">
            <!-- Check if logo is uploaded, if yes, display the image -->
            <img
              v-if="getCustomization.theme_config.logo.url"
              :src="getCustomization.theme_config.logo.url"
              alt="Logo"
              width="60"
            />
            <!-- Fallback text when no logo is uploaded -->
            <span v-else>your logo</span>
            © Powered By <img src="@/assets/facia.png" width="60" />
          </span>
          <v-spacer></v-spacer>
          <div
            class="continue-btn"
            :style="getCustomization.theme_config.buttons"
            v-if="selectedScreen !== 'Camera Screen' && selectedScreen !== 'Loader'"
          >
            {{
              selectedScreen === "Decline Screen"
                ? "Try Again"
                : "Continue >"
            }}
          </div>
        </div>
      </div>
    </div>
    <IframeCustomizationOptions @change-screen="changeScreen" />
  </div>
</template>
<script>
import IframeCameraScreen from "./IframeCameraScreen.vue";
import { mapActions, mapGetters } from "vuex";
import IframeDeclineScreen from "./IframeDeclineScreen.vue";
import ApiService from "@/services/Api";
import IframeCustomizationOptions from "./IframeCustomizationOptions.vue";
import IframeLoader from "./IframeLoader.vue";
import { showErrorMessage } from "@/utils/showError";
export default {
  name: "MerchantBackofficeFrontendIframeCustomization",
  components: {
    IframeCameraScreen,
    IframeDeclineScreen,
    IframeCustomizationOptions,
    IframeLoader,
  },
  data() {
    return {
      uploadedLogo: null, // This will store the logo image
      selectedScreen: "Default", // Initial state of the selected screen
  
    };
  },
  computed: {
    ...mapGetters("style", ["getCustomization", "isLoading"]),
  },
  methods: {
    ...mapActions("style", [
      "updateCustomization",
      "resetCustomization",
      "setAPITheme", // Add this new action
      "setLoading",
    ]),
    changeScreen(screen) {
      this.selectedScreen = screen; // Set selected screen based on the title clicked
    },
    getThemeAPI() {
      this.setLoading(true);
      ApiService.GetRequest("/merchant/iframe-theme")
        .then((response) => {
          this.setLoading(false);
          if (response.data && response.data.result) {
            // Set the API theme data to Vuex store
            this.setAPITheme(response.data.result);
            // Update the UI options after setting the theme
            // this.updateCustomizationOptions();
          }
        })
        .catch((error) => {
          showErrorMessage(error);
          this.setLoading(false);
        });
    },
  },
  async mounted() {
    await this.getThemeAPI(); // Wait for API data
    // this.updateCustomizationOptions(); // Then update UI
  },
};
</script>
<style scoped>
.iframe-customization {
  height: calc(100vh - 15vh);
}
.iframe-main {
  height: 100%;
  width: 70%;
  padding: 20px;
}
.iframe {
  height: 490px;
  width: 710px;
  border: 1px solid #e1e0e0;
  border-radius: 10px;
}
.iframe-header {
  height: 55px;
  border-bottom: 1px solid #e1e0e0;
  padding: 10px 10px;
  display: flex;
}
.iframe-content {
  height: 379px;
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-size: 14px;
  padding: 20px;
}
.no-padding {
  padding: 0;
}
.iframe-footer {
  height: 55px;
  border-top: 1px solid #e1e0e0;
  padding: 10px 30px;
  display: flex;
}
.brand-logo {
  display: flex;
  align-items: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  color: #b4b8c5;
}
.brand-logo img {
  height: 100%;
  object-fit: contain;
}
.heading {
  display: flex;
  align-items: center;
  font-family: Inter;
  font-weight: 600;
  margin-left: 10px;
}
.circle {
  width: 0.8125rem; /* Adjust width and height based on your circle size */
  height: 0.8125rem;
  background-color: #18ac14;
  color: white;
  text-align: center;
  line-height: 0.8125rem;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.concent-checkbox {
  margin-top: 6.19rem;
  padding: 0px 1.08rem;
}
.blue-background {
  background-color: blue;
}
.continue-btn {
  font-family: Inter !important;
  font-weight: 500 !important;
  padding: 0 16px;
  max-height: 2rem;
  max-width: 10.3rem;
  align-items: center;
  display: inline-flex;
  border-radius: 4px;
  border: 1px solid;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
