<template>
  <v-row
    :class="
      $vuetify.breakpoint.mdAndUp ? 'outer-main-div' : 'outer-main-div-mob'
    "
  >
    <v-col>
      <span class="heading" :style="getCustomization.theme_config.instructionDeclineScreen.instructionHeadingText">{{ ("Please follow the instruction below and try again") }}</span>
      <div class="pt-5 instruction-div">
        <CleanCameraSvg class="svg-icons" />
        <span class="instruction" :style="getCustomization.theme_config.instructionDeclineScreen.instructionSubheadingText">{{ ("Please clean your webcam / selfie camera.") }}</span>
      </div>
      <div class="pt-5 instruction-div">
        <FaceClearlyVisibleSvg class="svg-icons" />
        <span class="instruction" :style="getCustomization.theme_config.instructionDeclineScreen.instructionSubheadingText">{{ ("Please ensure that your face is clearly visible.") }}</span>
      </div>
      <div class="pt-5 instruction-div">
        <GoodLightningConditionsSvg class="svg-icons" />
        <span class="instruction" :style="getCustomization.theme_config.instructionDeclineScreen.instructionSubheadingText">{{ ("Please ensure good lighting conditions.") }}</span>
      </div>
      <div class="pt-5 instruction-div">
        <AvoidWearablesSvg class="svg-icons" />
        <span class="instruction" :style="getCustomization.theme_config.instructionDeclineScreen.instructionSubheadingText">{{ ("For best results, please take of any wearables.") }}</span>
      </div>
    </v-col>
    <v-divider v-if="$vuetify.breakpoint.mdAndUp" vertical></v-divider>
    <v-col>
      <div class="d-flex">
        <div class="image-div">
          <span class="upload-text" :style="getCustomization.theme_config.instructionDeclineScreen.instructionText">{{ ("Your Selfie") }}</span>
          <div class="image-preview-div">
            <img src="@/assets/stats/blur_face.jpg"  class="sample-image" />
          </div>
        </div>
        <div class="image-div">
          <span class="upload-text" :style="getCustomization.theme_config.instructionDeclineScreen.instructionText">{{ ("Perfect Selfie") }}</span>
          <div class="image-preview-div">
            <img src="@/assets/stats/sample-image.png" class="sample-image" />
          </div>
        </div>
      </div>
      <span class="decline-reason" :style="getCustomization.theme_config.instructionDeclineScreen.declineText">{{ ("Ray of light detected") }}</span>
    </v-col>
  </v-row>
</template>

<script>
import CleanCameraSvg from '../../../assets/iframe-svg/CleanCameraSvg.vue';
import GoodLightningConditionsSvg from '../../../assets/iframe-svg/GoodLightningConditionsSvg.vue';
import AvoidWearablesSvg from '../../../assets/iframe-svg/AvoidWearablesSvg.vue';
import FaceClearlyVisibleSvg from '../../../assets/iframe-svg/FaceClearlyVisibleSvg.vue';
import { mapActions, mapGetters } from "vuex";


export default {
  name: "IframeDeclineScreen",
  components: {
    CleanCameraSvg,
    GoodLightningConditionsSvg,
    AvoidWearablesSvg,
    FaceClearlyVisibleSvg,
  },
  props: {
    declineCode: {
      type: String,
    },
    userSelfie: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('style', ['getCustomization']),
    getDeclineCode() {
      return this.declineCode;
    },
    getUserSelfie() {
      return this.userSelfie;
    },
  },
  methods: {
    ...mapActions("style", [
      "updateCustomization",
      "resetCustomization",
    ]),
  },
};
</script>
<style scoped>
.outer-main-div {
  height: 370px;
  padding: 38px 12px;
  display: flex;
}
.outer-main-div-mob {
  height: auto;
  padding: 38px 12px;
  display: flex;
}
.heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #222942;
}
.svg-icons {
  height: 22px;
  margin-right: 8px;
}
.instruction {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;

  line-height: 20px;
  color: #222942;
}
.instruction-div {
  display: flex;
  align-items: center;
}
.image-div {
  height: 250px;
  width: 160px;
  border-radius: 20px;
  border: 1px solid rgb(160 163 172 / 41%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2px 4px;
}
.upload-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 27px;
  color: #222942;
}
.image-preview-div {
  border: 1px solid rgb(160 163 172 / 41%);
  height: 210px;
  width: 145px;
  border-radius: 20px;
  overflow: hidden;
}
.decline-reason {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  color: rgb(197, 3, 3);
}
.sample-image {
  height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
</style>