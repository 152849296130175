<template>
  <div class="style-main">
    <div class="header">
      <span>Iframe Customization Panel</span>
    </div>
    <div class="options-container">
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(item, i) in customizationOptions"
          :key="i"
          @click="changeScreen(item.title)"
        >
          <v-expansion-panel-header>
            {{ item.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content elevation="0">
            <div
              v-for="(option, index) in item.options"
              :key="index"
              class="option-item"
            >
              <template v-if="option.type === 'color'">
                <div class="option-column">
                  <span class="option-label">{{ option.label }}</span>
                  <div class="color-input-container">
                    <v-menu
                      v-model="option.menu"
                      :close-on-content-click="false"
                      offset-y
                      attach
                      :z-index="99999"
                    >
                      <template v-slot:activator="{ on }">
                        <div
                          class="color-preview"
                          :style="{ backgroundColor: option.value }"
                          v-on="on"
                        ></div>
                      </template>
                      <v-color-picker
                        v-model="option.value"
                        @input="
                          updateCustomizationValue(option.path, option.value)
                        "
                      ></v-color-picker>
                    </v-menu>
                    <input
                      type="text"
                      class="color-picker-text"
                      :placeholder="option.label"
                      v-model="option.value"
                      @input="
                        updateCustomizationValue(option.path, option.value)
                      "
                    />
                  </div>
                </div>
              </template>
              <template v-else-if="option.type === 'number'">
                <div class="option-column">
                  <span class="option-label">{{ option.label }}</span>
                  <div class="number-input">
                    <input
                      v-model="option.value"
                      type="number"
                      :min="6"
                      :max="18"
                      @input="handleNumberInput(option)"
                      dense
                      hide-details
                      class="input-field-style"
                    />
                  </div>
                </div>
              </template>
              <template v-if="option.type === 'file'">
                <span class="option-label">{{ option.label }}</span>
                <IframeLogoUpload
                  :ref="'uploader-' + option.path"
                  :upload-path="option.path"
                  @file-uploaded="handleFileUploaded"
                  @file-removed="handleFileRemoved"
                />
              </template>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div class="button-container">
      <v-btn
        @click="handleResetCustomization"
        class="reset-btn text-capitalize"
        small
        elevation="0"
      >
        Discard
      </v-btn>

      <v-btn
        @click="handleApplyCustomization"
        class="apply-btn text-capitalize"
        small
        :loading="loader"
        elevation="0"
      >
        Save Changes
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import IframeLogoUpload from "./IframeLogoUpload.vue";
import ApiService from "@/services/Api";
import { showErrorMessage } from "@/utils/showError";
import { showNotificationBar } from "@/utils/showError";
export default {
  name: "IframeCustomizationOptions",
  components: {
    IframeLogoUpload,
  },
  data() {
    return {
      customizationOptions: [
        {
          title: "Consent Screen",
          options: [
            {
              label: "Heading Color",
              type: "color",
              value: "#1d2c42",
              path: "theme_config.consentScreen.heading.color",
            },
            {
              label: "Heading Font Size",
              type: "number",
              value: 18,
              path: "theme_config.consentScreen.heading.fontSize",
            },
            {
              label: "Description Color",
              type: "color",
              value: "#1d2c42",
              path: "theme_config.consentScreen.description.color",
            },
            {
              label: "Description Font Size",
              type: "number",
              value: 14,
              path: "theme_config.consentScreen.description.fontSize",
            },
          ],
        },
        {
          title: "Loader",
          options: [
            {
              label: "",
              type: "file",
              path: "theme_config.loader.url",
            },
            {
              label: "Text Color",
              type: "color",
              value: "#1d2c42",
              path: "theme_config.loader.textStyle.color",
            },
            {
              label: "Text Font Size",
              type: "number",
              value: 14,
              path: "theme_config.loader.textStyle.fontSize",
            },
          ],
        },
        {
          title: "Camera Screen",
          type: "button",
          options: [
            {
              label: "Screen Background Color",
              type: "color",
              value: "#f5f5f5",
              path: "theme_config.camera.ovalBackgroundColor.color",
            },
            {
              label: "Message Text Color",
              type: "color",
              value: "#1d2c42",
              path: "theme_config.camera.ovalCameraInstructionText.color",
            },
            {
              label: "Message Text Font Size",
              type: "number",
              value: 14,
              path: "theme_config.camera.ovalCameraInstructionText.fontSize",
            },
          ],
        },
        {
          title: "Decline Screen",
          type: "button",
          options: [
            {
              label: "Heading Text Color",
              type: "color",
              value: "#1d2c42",
              path: "theme_config.instructionDeclineScreen.instructionHeadingText.color",
            },
            {
              label: "Heading Text Font Size",
              type: "number",
              value: 18,
              path: "theme_config.instructionDeclineScreen.instructionHeadingText.fontSize",
            },
            {
              label: "Instruction Text Color",
              type: "color",
              value: "#1d2c42",
              path: "theme_config.instructionDeclineScreen.instructionSubheadingText.color",
            },
            {
              label: "Instruction Text Font Size",
              type: "number",
              value: 16,
              path: "theme_config.instructionDeclineScreen.instructionSubheadingText.fontSize",
            },
            {
              label: "Image Text Color",
              type: "color",
              value: "#1d2c42",
              path: "theme_config.instructionDeclineScreen.instructionText.color",
            },
            {
              label: "Image Text Font Size",
              type: "number",
              value: 14,
              path: "theme_config.instructionDeclineScreen.instructionText.fontSize",
            },
            {
              label: "Decline Text Color",
              type: "color",
              value: "#1d2c42",
              path: "theme_config.instructionDeclineScreen.declineText.color",
            },
            {
              label: "Decline Text Font Size",
              type: "number",
              value: 14,
              path: "theme_config.instructionDeclineScreen.declineText.fontSize",
            },
          ],
        },
        {
          title: "Logo",
          options: [
            {
              label: "",
              type: "file",
              path: "theme_config.logo.url",
            },
          ],
        },
        {
          title: "Buttons",
          options: [
            {
              label: "Background Color",
              type: "color",
              value: "#173dd0",
              path: "theme_config.buttons.background",
            },
            {
              label: "Text Color",
              type: "color",
              value: "#ffffff",
              path: "theme_config.buttons.color",
            },
            {
              label: "Font Size",
              type: "number",
              value: 14,
              path: "theme_config.buttons.fontSize",
            },
            {
              label: "Border",
              type: "color",
              value: "#173dd0",
              path: "theme_config.buttons.borderColor",
            },
          ],
        },
      ],
      showColorPicker: false,
      loader: false,
      snackbar: false,
      currentColor: "",
      currentColorOption: null,
    };
  },
  watch: {
    getCustomization: {
      handler() {
        this.updateCustomizationOptions(); // Refresh options when theme changes
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("style", ["getCustomization", "isLoading"]),
  },
  methods: {
    ...mapActions("style", [
      "updateCustomization",
      "resetCustomization",
      "setAPITheme", // Add this new action
      "setLoading",
    ]),
    handleResetCustomization() {
      this.resetCustomization(); // Correct action name
      // Reset all file uploaders
      this.customizationOptions.forEach((section) => {
        section.options.forEach((option) => {
          if (option.type === "file") {
            const uploaderRef = this.$refs[`uploader-${option.path}`];

            if (uploaderRef && uploaderRef[0]) {
              uploaderRef[0].resetPreview();
            }
          }
        });
      });
      this.updateCustomizationOptions();
    },
    changeScreen(screen) {
      this.$emit("change-screen", screen);
    },
    updateCustomizationValue(path, value) {
      this.updateCustomization({ path, value });
    },
    getValueFromPath(obj, path) {
      try {
        return path.split(".").reduce((acc, part) => {
          if (acc === null || acc === undefined) return undefined;
          return acc[part];
        }, obj);
      } catch (error) {
        console.error(`Error getting value for path ${path}:`, error);
        return undefined;
      }
    },
    updateCustomizationOptions() {
      const updatedTheme = this.getCustomization;
      this.customizationOptions.forEach((section) => {
        section.options.forEach((option) => {
          const value = this.getValueFromPath(updatedTheme, option.path);
          if (value !== undefined && value !== null) {
            if (option.type === "number") {
              // Remove 'px' and parse as integer
              const numericValue = parseInt(value.toString().replace("px", ""));
              option.value = isNaN(numericValue) ? option.value : numericValue;
            } else {
              option.value = value;
            }
          }
        });
      });
    },
    uploadFile(path, event) {
      if (!event || !event.target) {
        console.error("Event or target is undefined.");
        return;
      }
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.updateCustomization({
            path: path,
            value: e.target.result,
          });
        };
        reader.readAsDataURL(file);
      } else {
        console.warn("No file selected.");
      }
    },
    handleNumberInput(option) {
      // Get the input value as a string to limit its length
      let value = option.value.toString();
      // Limit the input length to 2 characters (max two digits)
      if (value.length > 2) {
        value = value.slice(0, 2);
      }
      // Parse the value as a number
      let parsedValue = parseInt(value, 10);
      // If the value is NaN (i.e., user cleared the input), set it to 0
      if (isNaN(parsedValue)) {
        parsedValue = 0;
      }
      // Ensure the value does not exceed 18
      parsedValue = Math.min(parsedValue, 18);
      // Update the option value
      this.updateCustomizationValue(option.path, parsedValue + "px");
      option.value = parsedValue; // Update the v-model value with the parsed value
    },
    handleFileUploaded({ imageUrl, path }) {
      this.updateCustomizationValue(path, imageUrl);
    },
    handleFileRemoved(path) {
      // Reset the appropriate URL (either loader or logo) based on the provided path
      this.updateCustomizationValue(path, "");
    },
    handleApplyCustomization() {
      this.applyThemeAPI();
      this.loader = true;
    },
    //update custom theme values post, on apply this will call
    applyThemeAPI() {
      let payload = {
        theme_config: this.getCustomization.theme_config,
      };
      ApiService.PostRequest("/merchant/iframe-theme", payload)
        .then((response) => {
          showNotificationBar("success",response.data.message);
          this.loader = false;
          this.snackbar = true;
        })
        .catch((error) => {
          showErrorMessage(error);
          this.loader = false;
        });
    },
  },
  mounted() {
    this.updateCustomizationOptions(); // Then update UI
  },
};
</script>
<style scoped>
.style-main {
  width: 31%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e1e0e0;
  background-color: #eeeeee;
}
.color-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #dee2ed;
  border-radius: 5px;
  padding: 8px 10px;
  cursor: pointer; /* Shows pointer to indicate clickable container */
}
.color-preview {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-right: 8px; /* Space between preview and input */
}
.color-picker-text {
  font-size: 12px;
  font-weight: 400;
  color: #002438;
  font-family: "Graphik-Regular", sans-serif;
  width: 100%;
  border: none; /* Removes border from inner input */
  outline: none; /* Removes focus outline */
  cursor: text; /* Changes cursor to text when over input */
  text-align: right;
}
.input-field-style {
  font-size: 12px;
  font-weight: 400;
  color: #002438;
  font-family: "Graphik-Regular", sans-serif;
  position: relative;
  width: 100%;
  border: 1px solid #dee2ed;
  border-radius: 5px;
  padding: 8px 10px;
  outline: none;
}
.option-column {
  display: flex;
  flex-direction: column;
}
.header {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin: 15px;
  display: flex;
  margin-left: 20px;
}
.options-container {
  height: 100%;
  overflow-y: auto; /* Enable scrolling */
  padding: 0 20px;
  width: 100%;
}
.option-item {
  margin-bottom: 15px;
}
.option-label {
  font-size: 14px;
  color: #333;
}
.color-input,
.number-input {
  display: flex;
  align-items: center;
  gap: 8px;
}
.number-input {
  display: flex;
  align-items: center;
}
.number-text-field {
  width: 50px;
}
.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 20px;
}
.apply-btn {
  border-radius: 6px !important;
  background: #213cc8 !important;
  height: 32px !important;
  padding: 10px !important;
  color: #fff !important;
  font-family: Inter !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.reset-btn {
  border-radius: 6px !important;
  border: 1px solid #e3e3e3 !important;
  background: #fff !important;
  height: 32px !important;
  padding: 10px !important;
  color: #0a2540 !important;
  font-family: Inter !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.close-btn {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 1;
}
.v-btn:not(.v-btn--round).v-size--small {
  height: 32px;
  min-width: 154px;
}
::v-deep .v-expansion-panel-header {
  padding: 8px 15px;
  font-size: 15px;
  font-weight: 500;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.v-expansion-panel::before {
  box-shadow: none;
}
::v-deep .v-expansion-panel-content__wrap {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 15px 5px;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  border-radius: 10px;
  margin-top: 10px;
}
.v-expansion-panel {
  border-radius: 10px;
}
.v-expansion-panel:not(:first-child)::after {
  border-top: 0px;
}
</style>
