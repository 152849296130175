<template>
  <div class="message-container" :style="getCustomization.theme_config.loader.textStyle">
    <img :src="currentImage" class="gif" alt="Loading" />
    <div class="message" :style="getCustomization.theme_config.loader.textStyle">
      {{ currentMessage }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: 'IframeLoader',
  data() {
    return {
      messages: ['Proof Uploading', 'Performing Liveness'],
      currentMessageIndex: 0,
      interval: null,
    };
  },
  computed: {
    ...mapGetters('style', ['getCustomization']),
    currentMessage() {
      return this.messages[this.currentMessageIndex];
    },
    currentImage() {
    return this.getCustomization.theme_config.loader.url || require('@/assets/stats/double-loader.gif');
  }
  },
  methods: {
    ...mapActions("style", [
      "updateCustomization",
      "resetCustomization",
    ]),
    rotateMessage() {
      this.currentMessageIndex = (this.currentMessageIndex + 1) % this.messages.length;
    },
  },
  mounted() {
    this.interval = setInterval(this.rotateMessage, 2000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
};
</script>

<style scoped>
.message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width:100%;
}

.gif {
  max-width: 100%; /* Allows the gif to scale responsively */
  max-height: 100px; /* I set this as a max height, adjust this if needed.. */
  height: auto; /* Maintains aspect ratio */
  width: auto; /* Maintains aspect ratio */
}

.message {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #1d2c42;
}
</style>