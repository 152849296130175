<template>
  <div class="appBackGround">
    <v-divider class="ml-3"></v-divider>
    <v-form
      ref="form"
      @submit.prevent="updateProfile"
      style="margin: auto !important; width: 97%"
      enctype="multipart/form-data"
    >
      <div class="d-flex gap-20 pt-5 pb-5">
        <v-avatar color="#f6f6f6" size="101" class="relative">
          <v-img v-if="avatar" :src="avatar" />
          <v-img v-else :src="defaultImage" />
          <v-file-input
            prepend-icon="mdi-pencil"
            accept="image/*"
            hide-input
            truncate-length="15"
            v-model="img"
            @change="uploadImage"
            class="profile-image-select"
          ></v-file-input>
        </v-avatar>

        <div class="d-flex flex-column justify-center ml-3">
          <h4 class="mb-1">Update Profile</h4>
          <p class="font-12 darkGrey--text mb-0">
            image size should be at least 400px and less than 1mb. <br />
            Allowed file png and jpg.
          </p>
        </div>
      </div>
      <v-divider class="ml-3"></v-divider>
      <div class="font-14 fw-600 ml-3 mt-6 mb-1 greyColor--text">
        Update Personal Details
      </div>
      <v-form ref="updateProfile">
        <v-row class="pa-3 mb-2">
          <v-col
            v-for="(field, i) in fields"
            :key="i"
            :cols="$vuetify.breakpoint.smAndDown ? '12' : '4'"
          >
            <div class="user-standard-input">
              <label class="font-12 fw-500 secondary--text">{{
                field.label
              }}</label>
              <v-text-field
                v-if="field.name != 'phone_no'"
                v-model="userDetails[field['name']]"
                :type="field.type"
                :readonly="field.type == 'email'"
                :disabled="field.type == 'email'"
                outlined
                height="44"
                hide-details="auto"
                dense
                :rules="field.rules"
                required
                class="user-details-field font-12 fw-400 mt-2"
              ></v-text-field>
              <vue-tel-input
                v-else
                class="user-details-field font-12 fw-400 mt-2"
                hide-details="auto"
                autoDefaultCountry
                mode="international"
                validCharactersOnly
                v-model="userDetails[field['name']]"
                required
                style="height: 44px"
              ></vue-tel-input>
            </div>
          </v-col>
        </v-row>
        <v-divider class="ml-3"></v-divider>
        <div class="font-14 fw-600 ml-3 mt-6 mb-1 greyColor--text">
          Update Password
        </div>
        <v-row class="pa-3 mb-2">
          <v-col
            v-for="(passwordField, i) in passwordFields"
            :key="passwordField + i"
            :cols="$vuetify.breakpoint.smAndDown ? '12' : '4'"
          >
            <div class="user-standard-input">
              <label class="font-12 fw-500 secondary--text">{{
                passwordField.label
              }}</label>
              <v-text-field
                v-model="userDetails[passwordField['name']]"
                :id="passwordField['id']"
                placeholder="************"
                :append-icon="passwordField.visible ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="toggleShowPassword(i)"
                :type="passwordField.visible ? 'text' : 'password'"
                outlined
                height="44"
                hide-details="auto"
                required
                dense
                :rules="
                  passwordField.name === 'new_password' ? passwordRules : []
                "
                class="user-details-field font-12 fw-400 greyColor--text mt-2"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </v-form>
      <v-divider class="ml-3"></v-divider>
      <div class="d-flex Custom-2FA">
        <div class="font-14 fw-600 greyColor--text ml-3 mt-7">
          Two factor authentication (2FA)
        </div>
        <v-switch
          v-model="userDetails.two_fa"
          inset
          :label="userDetails.two_fa == true ? 'Disable' : 'Enable'"
          class="user-switch ml-10 mt-6 font-12 fw-400"
          hide-details
          flat
          color="primary"
          dense
          :ripple="false"
        ></v-switch>
      </div>
      <div class="mt-5 ml-3">
        <v-btn
          elevation="0"
          color="primary"
          width="146"
          height="40"
          :loading="loading"
          class="textColor--text text-transform-none font-13 fw-500 mr-3"
          @click="updateProfile"
          >Save Changes</v-btn
        >
      </div>
    </v-form>
  </div>
</template>

<script>
import ApiService from "@/services/Api";
import { mapGetters } from "vuex";
import {
  showErrorMessage,
  showSimpleErrorMessage,
  showNotificationBar,
} from "@/utils/showError";
import profile from "@/assets/profile/profile.png";
const nameValidator = (v) => !!v || "Name is required";
export default {
  data() {
    return {
      userDetails: {
        two_fa: false,
      },
      uploadedImage: "",
      img: null,
      defaultImage: profile,
      loading: false,
      fields: [
        {
          label: "First Name",
          name: "first_name",
          type: "text",
          rules: [nameValidator],
        },
        {
          label: "Middle Name",
          name: "middle_name",
          type: "text",
          rules: [],
        },
        {
          label: "Last Name",
          name: "last_name",
          type: "text",
          rules: [nameValidator],
        },
        {
          label: "Email",
          name: "email",
          type: "email",
          rules: [],
        },
      ],
      passwordFields: [
        {
          label: "Current Password",
          name: "current_password",
          visible: false,
          type: "password",
          id: "current_password",
        },
        {
          label: "New Password",
          name: "new_password",
          visible: false,
          type: "password",
          id: "new_password",
        },
        {
          label: "Confirm Password",
          name: "new_password_confirmation",
          visible: false,
          type: "password",
          id: "new_password_confirmation",
        },
      ],
      show: false,
      avatar: null,
      passwordRules: [
        (v) =>
          v === undefined ||
          v === null ||
          v.length === 0 ||
          /[A-Z]/.test(v) ||
          "Password must include at least one uppercase letter.",
        (v) =>
          v === undefined ||
          v === null ||
          v.length === 0 ||
          /[a-z]/.test(v) ||
          "Password must include at least one lowercase letter.",
        (v) =>
          v === undefined ||
          v === null ||
          v.length === 0 ||
          /\d/.test(v) ||
          "Password must include at least one number.",
        (v) =>
          v === undefined ||
          v === null ||
          v.length === 0 ||
          /[@$!%*?&]/.test(v) ||
          "Password must include at least one special character (@$!%*?&).",
        (v) =>
          v === undefined ||
          v.length === 0 ||
          v.length >= 8 ||
          "Password must be at least 8 characters long.",
      ],
      confirmPasswordRules: [
        (v) => !!v || "Type confirm password",
        (v) =>
          v === this.userDetails.new_password ||
          "The password confirmation does not match.",
      ],
      nameRules: [(v) => !!v || "Name is required"],
    };
  },
  computed: {
    ...mapGetters(["userEmailGetter"]),
  },
  methods: {
    /**
     * Handles the image upload event.
     * @param {File} e - The file to be uploaded.
     */
    uploadImage(e) {
      let reader = new FileReader();
      reader.readAsDataURL(e);
      reader.onload = (event) => {
        this.avatar = event.target.result;
      };
      this.uploadedImage = e;
    },

    /**
     * Updates the user profile.
     */
    updateProfile() {
      if (!this.$refs.updateProfile.validate()) {
        return showSimpleErrorMessage("Please fill required fields correctly!");
      }

      const current_password =
        document.getElementById("current_password").value;
      const new_password = document.getElementById("new_password").value;
      const new_password_confirmation = document.getElementById(
        "new_password_confirmation"
      ).value;

      if (current_password.trim() !== "") {
        // Current password is not empty

        if (
          new_password.trim() === "" ||
          new_password_confirmation.trim() === ""
        ) {
          // New password or new password confirmation is empty
          return showSimpleErrorMessage(
            " Please enter both new password and confirm password."
          );
          // You can display an error message or take appropriate action
        }
      } else if (new_password.trim() !== "") {
        // Current password is not empty

        if (
          current_password.trim() === "" ||
          new_password_confirmation.trim() === ""
        ) {
          // New password or new password confirmation is empty
          return showSimpleErrorMessage(
            " Please enter both current password and confirm password."
          );
          // You can display an error message or take appropriate action
        }
      } else if (new_password_confirmation.trim() !== "") {
        // Current password is not empty

        if (current_password.trim() === "" || new_password.trim() === "") {
          // New password or new password confirmation is empty
          return showSimpleErrorMessage(
            " Please enter both current password and new password."
          );
          // You can display an error message or take appropriate action
        }
      }

      this.loading = true;
      var formData = new FormData();
      formData.append("avatar", this.uploadedImage);
      formData.append("first_name", this.userDetails.first_name);
      formData.append("middle_name", this.userDetails.middle_name);
      formData.append("last_name", this.userDetails.last_name);
      formData.append("current_password", this.userDetails.current_password);
      formData.append(
        "new_password",
        this.userDetails.new_password ? this.userDetails.new_password : ""
      );
      formData.append("phone_no", this.userDetails.phone_no);
      formData.append(
        "new_password_confirmation",
        this.userDetails.new_password_confirmation
          ? this.userDetails.new_password_confirmation
          : ""
      );
      formData.append("two_fa", this.userDetails.two_fa == false ? 0 : 1);
      ApiService.PostRequest("/merchant/profile-update", formData)
        .then((res) => {
          this.loading = false;
          this.$store.dispatch("UPDATE_PROFILE", {
            userImage: res.data.result.data.avatar,
            userName:
              this.userDetails.first_name + " " + this.userDetails.last_name,
          });
          this.userDetails.current_password = "";
          this.userDetails.new_password = "";
          this.userDetails.new_password_confirmation = "";
          showNotificationBar("success", res.data.message);
        })
        .catch((error) => {
          this.loading = false;
          showErrorMessage(error);
          if (error.response.status === 401) {
            this.$store.dispatch("logout");
            this.$store.dispatch("SET_LOGOUT");
            this.$router.push("/");
          }
        });
    },

    /**
     * Retrieves the user's profile details.
     */
    getProfileDetails() {
      ApiService.GetRequest("/merchant/profile")
        .then((res) => {
          this.userDetails = { current_password: "", ...res.data.result.data };
          this.avatar = res.data.result.data.avatar;
          this.$refs.updateProfile.resetValidation();
        })
        .catch((error) => {
          showErrorMessage(error);
        });
    },

    /**
     * Toggles the visibility of password fields.
     * @param {string} key - The key of the password field to toggle.
     */
    toggleShowPassword(key) {
      this.passwordFields[key].visible = !this.passwordFields[key].visible;
    },
  },
  mounted() {
    this.getProfileDetails();
  },
};
</script>

<style scoped>
@media (max-width: 478px) {
  .sec-font {
    padding-right: 20px !important;
  }
}
.avatar-user {
  padding: 30px 30px 0px 0px !important;
}
.input-hide {
  visibility: hidden;
}
.upload-avatar .v-avatar img,
.v-avatar svg,
.v-avatar .v-icon,
.v-avatar .v-image,
.v-avatar .v-responsive__content {
  width: 100px !important;
}
.user-edit-action-btn {
  background-color: #5c94a3 !important;
  padding: 0px !important;
  margin: -55px 0px 0px 86px !important;
  width: 24px !important;
  height: 24px !important;
  border-radius: 12px !important;
  color: #fff !important;
}
.user-responsive-content {
  margin: 48px 0px 0px 70px !important;
}
label[for="profile-image-select"],
::v-deep .v-file-input {
  position: absolute;
  top: 109%;
  left: 109%;
  transform: translate(-100%, -130%);
}
.relative {
  position: relative;
  overflow: visible;
}
::v-deep .v-avatar .v-input__prepend-outer {
  background: #249da8 !important;
  border-radius: 50%;
  padding: 2px;
}
::v-deep .v-avatar .v-input__prepend-outer button {
  color: white;
  font-size: 20px;
}
.vue-tel-input:focus-within {
  box-shadow: unset !important;
  border-color: #e1e4ec !important;
}
.vue-tel-input {
  border: 1px solid #e1e4ec !important;
}
.Custom-2FA {
  display: none;
}
</style>
