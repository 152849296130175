<template>
  <div>
    <div v-if="selectedFile && previewUrl" class="logo-preview-area">
      <div class="image-preview">
        <img :src="previewUrl" alt="Selected file" />
        <button class="close-button" @click="removeFile">×</button>
      </div>
    </div>
    <div class="button-container">
      <button class="update-logo-button" @click="triggerFileInput">
        {{ buttonText }}
      </button>
    </div>
    <input
      type="file"
      ref="fileInput"
      style="display: none"
      @change="handleFileChange"
      accept="image/*"
    />
    <v-snackbar
      v-model="showToast"
      :timeout="2000"
      top
      right
      color="red lighten-1"
      class="custom-snackbar"
    >
      <span>File size exceeds the 2MB limit.</span>
      <v-btn icon @click="showToast = false" class="close-btn">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "IframeLogoUpload",
  props: {
    uploadPath: String,
  },
  data() {
    return {
      selectedFile: null,
      previewUrl: "",
      showToast: false,
    };
  },
  computed: {
    fileSize() {
      return this.selectedFile
        ? (this.selectedFile.size / 1024).toFixed(2) + " KB"
        : "";
    },
    buttonText() {
      if (this.uploadPath.includes("loader")) {
        return "Upload Loader";
      } else if (this.uploadPath.includes("logo")) {
        return "Upload Logo";
      }
      return "Upload Image";
    },
  },
  methods: {
    resetPreview() {
      if (this.previewUrl) {
        URL.revokeObjectURL(this.previewUrl);
      }
      this.selectedFile = null;
      this.previewUrl = "";
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
      this.$emit("file-removed", this.uploadPath);
    },
    triggerFileInput() {
      // Reset the file input value before triggering click
      this.$refs.fileInput.value = "";
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        if (file.size <= 2 * 1024 * 1024) {
          this.selectedFile = file;
          this.previewUrl = URL.createObjectURL(file);

          const reader = new FileReader();
          reader.onload = (e) => {
            this.$emit("file-uploaded", {
              imageUrl: e.target.result,
              path: this.uploadPath,
            });
          };
          reader.readAsDataURL(file);
        } else {
          this.showToast = true;
          // Reset file input after showing error
          event.target.value = "";
        }
      }
    },
    removeFile() {
      this.resetPreview();
      // Revoke object URL to prevent memory leaks
      if (this.previewUrl) {
        URL.revokeObjectURL(this.previewUrl);
      }
      this.selectedFile = null;
      this.previewUrl = "";
      // Reset the file input
      this.$refs.fileInput.value = "";
      this.$emit("file-removed", this.uploadPath);
    },
  },
  // Clean up on component destroy
  beforeDestroy() {
    if (this.previewUrl) {
      URL.revokeObjectURL(this.previewUrl);
    }
  },
};
</script>
<style scoped>
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-preview-area {
  width: 300px; /* Set a fixed width */
  height: 150px; /* Set a fixed height */
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0;
}
.image-preview {
  position: relative;
  width: 100%; /* Ensure it fills the fixed-size container */
  height: 100%;
}
.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensures the image fits within the container */
  display: block;
}
.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  font-size: 16px;
  color: #666;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.update-logo-button {
  background-color: #213cc8 !important;
  color: #fff !important;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  height: 35px;
  min-width: 110px;
  border-radius: 4px;
}
.update-logo-button:hover {
  background: #2942ed !important;
}
.custom-snackbar {
  position: fixed !important;
  top: 60px !important;
  right: 16px !important;
  z-index: 9999 !important;
}
.close-btn {
  position: absolute;
  top: 2px;
  right: 2px;
  color: white;
}
</style>
