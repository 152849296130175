<template>
  <div class="camera-screen" :style="cameraScreenStyle">
    <div class="message" :style="instructionTextStyle">
      Position your face in Oval
    </div>
    <div class="oval">
      <img src="@/assets/stats/face.png" alt="Face" class="oval-image" />
    </div>
  </div>
</template>

<script>
import { mapGetters,mapActions } from "vuex";

export default {
  name: 'IframeCameraScreen',
  computed: {
    ...mapGetters('style', ['getCustomization']),
    cameraScreenStyle() {
  return {
    backgroundColor: this.getCustomization.theme_config.camera.ovalBackgroundColor.color,
  };
},
    instructionTextStyle() {
      const { color, fontSize } = this.getCustomization.theme_config.camera.ovalCameraInstructionText;
      return {
        color,
        fontSize,
      };
    },
  },
  methods: {
  ...mapActions("style", [
      "updateCustomization",
      "resetCustomization",
    ]),
  },
};
</script>
<style scoped>
.camera-screen {
  height: 100%;
  position: relative;
}
.oval {
  width: 170px;
  height: 250px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
}
.oval-image {
  width: 170px;
  height: 250px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
}
.message {
  display: flex;
  justify-content: center;
  z-index: 4;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  margin-top: 15px;
}
</style>
